import api from '../services/api'
import Helper from './helper'

class FinanceService {
  get(p, s, n) {
    return api.get(`/fin?${Helper.getPageQuery(p, s)}&n=${n}`)
  }

  settleSalary(row) {
    return api.post('/fin/SettleSalary', row)
  }
}

export default new FinanceService()
