<template>
  <div>
    <vxe-form :data="filter" @submit="loadData">
      <vxe-form-item>
        <template v-slot>
          <vxe-input
            v-model="filter.name"
            type="search"
            placeholder="输入员工姓名"
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item>
        <template v-slot>
          <b-form-radio-group
            :disabled="true"
            v-model="filter.selected"
            :options="options"
            plain
          ></b-form-radio-group>
        </template>
      </vxe-form-item>

      <vxe-form-item>
        <template v-slot>
          <vxe-button type="submit" status="primary">查询</vxe-button>
        </template>
      </vxe-form-item>

      <vxe-form-item span="24" v-if="tableData.employeeName">
        <template v-slot
          ><b-card
            :img-src="require('../assets/user.png')"
            img-alt="user"
            img-left
            class="mb-3"
            :title="tableData.employeeName"
          >
            <b-card-text>
              <b-badge variant="light"
                ><i class="fa fa-id-card-o" aria-hidden="true"></i>
                {{ tableData.identity }}</b-badge
              >

              <b-badge variant="light"
                ><i class="fa fa-mobile" aria-hidden="true"></i>
                {{ tableData.mobile }}</b-badge
              >
              <hr />
              <h3>
                未结算工资：<span>{{ tableData.unSettledSalary }}</span>
              </h3>
              <hr />

              <vxe-button
                :disabled="tableData.unSettledSalary === 0"
                status="primary"
                @click="settleSalary(tableData.id)"
                >结算工资</vxe-button
              >
            </b-card-text>
          </b-card>
        </template>
      </vxe-form-item>
    </vxe-form>

    <vxe-table
      border
      resizable
      row-key
      show-overflow
      highlight-hover-row
      ref="xTable"
      :data="tableData.rows"
      :loading="loading"
    >
      <vxe-table-column type="seq" width="60"></vxe-table-column>
      <!-- Id 产品名称 状态 工序 颜色 尺码 数量 单价 开工时间 完工时间 结算时间 -->
      <vxe-table-column field="product" title="产品名称"></vxe-table-column>
      <vxe-table-column field="status" title="状态"></vxe-table-column>
      <vxe-table-column field="procedure" title="工序"></vxe-table-column>
      <vxe-table-column field="color" title="颜色"></vxe-table-column>
      <vxe-table-column field="size" title="尺码"></vxe-table-column>
      <vxe-table-column field="count" title="数量"></vxe-table-column>
      <vxe-table-column field="price" title="单价"></vxe-table-column>
      <vxe-table-column
        field="created"
        title="开工时间"
        formatter="dateTime"
      ></vxe-table-column>
      <vxe-table-column
        field="finishedTime"
        title="完工时间"
        formatter="dateTime"
      ></vxe-table-column>
      <vxe-table-column
        field="settledTime"
        title="结算时间"
        formatter="dateTime"
      ></vxe-table-column>
    </vxe-table>

    <vxe-pager
      perfect
      :current-page.sync="tableData.page"
      :page-size.sync="tableData.pageSize"
      :total="tableData.totalResult"
      @page-change="pageChange"
    >
    </vxe-pager>
  </div>
</template>

<script>
import FinanceService from '../services/finance.service'

export default {
  data() {
    return {
      loading: false,
      filter: { selected: '0', name: '' },
      options: [
        { text: '未结算', value: '0' },
        { text: '已结算', value: '1' }
      ],
      tableData: {}
      // Id 产品名称 状态 工序 颜色 尺码 数量 单价 开工时间 完工时间 结算时间
    }
  },
  methods: {
    settleSalary(id) {
      this.$XModal.confirm('您确定要结算工资吗？').then(async (type) => {
        if (type === 'confirm') {
          try {
            await FinanceService.settleSalary({ id: id })
            await this.loadData()
          } catch {}
        }
      })
    },
    async loadData() {
      if (this.filter.name === '') {
        this.$XModal.message({ message: '请输入姓名', status: 'error' })
        return
      }

      try {
        this.loading = true
        const response = await FinanceService.get(1, 10, this.filter.name)
        this.tableData = response.data
      } catch {}

      this.loading = false
    },
    async pageChange({ currentPage, pageSize }) {
      this.loading = true
      try {
        const response = await FinanceService.get(
          currentPage,
          pageSize,
          this.filter.name
        )
        this.tableData = response.data
      } catch {
        this.tableData = {}
      }
      this.loading = false
    }
  }
}
</script>
